'use client'

import { useEffect, useMemo, useState } from 'react'

import { Flex } from '@/components/flex'
import { SKU_RECENTLY_VIEWED_PRODUCT } from '@/providers/product-data/utils'
import { selectors } from '@/common/constants/selectors-constants'
import { obsoloteGetPrice } from '@/common/utils'
import { fetchTopProducts } from '../../homepage-api'
import { ProductListItemSkeleton } from '@/components/product-list-item/product-list-item-skeleton'
import { ProductListItem } from '@/components/product-list-item/product-list-item'
import {
  PurchaseDialog,
  PurchaseDialogProduct,
} from '@/components/purchase-dialog/purchase-dialog'
import { useCartContext, useStoreContext } from '@/providers'
import {
  AddToCartEventHandlerParams,
  SimpleVariantAddToCartParams,
} from '@/providers/cart/utils/add-to-cart.utils'
import {
  ProductListItem as ProductListItemType,
  ProductType,
} from '@/common/types'
import { useDisclosure } from '@/common/hooks/use-disclosure'
import { consoleError } from '@/common/utils/console'

export type HomepageProductsGridProps = {
  baseUrl: string
}

export function HomepageProductsGrid({
  baseUrl,
}: HomepageProductsGridProps): JSX.Element {
  const { storeCode } = useStoreContext()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { addToCart, isCartRefetching, isAddingToCart } = useCartContext()
  const [dialogProductData, setDialogProductData] = useState<
    PurchaseDialogProduct | undefined
  >()
  const [topProducts, setTopProducts] = useState<
    ProductListItemType[] | undefined
  >()
  const lastViewedProductsSkus: string[] = useMemo(() => {
    try {
      return JSON.parse(
        localStorage.getItem(SKU_RECENTLY_VIEWED_PRODUCT) ?? '[]',
      )
    } catch (e) {
      consoleError(e)
      return []
    }
  }, [])

  useEffect(() => {
    if (lastViewedProductsSkus) {
      fetchTopProducts(lastViewedProductsSkus, storeCode).then((res) => {
        setTopProducts(res)
      })
    }
  }, [setTopProducts, lastViewedProductsSkus, storeCode])

  const handleConfigOnAddToCartEvent = async ({
    product,
    variant,
    count,
    addToCart: addToCartHandler,
  }: AddToCartEventHandlerParams) => {
    const { finalPrice } = obsoloteGetPrice(variant?.product)

    await addToCartHandler({
      productName: product?.name ?? '',
      parentSku: product?.sku ?? '',
      sku: variant?.product?.sku ?? '',
      quantity: count,
      brand: product?.manufacturer_info?.name ?? '',
      price: finalPrice?.value_excl_tax
        ? Math.round(finalPrice.value_excl_tax * 100) / 100
        : undefined,
      category: product?.breadcrumb_en ?? '',
    })
  }

  const handleSimpleOnAddToCartEvent = (
    params: SimpleVariantAddToCartParams,
  ) => {
    addToCart?.({
      ...params,
      quantity: 1,
    })
  }

  const isCartButtonDisabled = !addToCart || isCartRefetching || isAddingToCart

  return (
    <>
      {dialogProductData && (
        <PurchaseDialog
          productListData={dialogProductData}
          onClose={() => {
            onClose()
            setDialogProductData(undefined)
          }}
          onAddToCartEvent={handleConfigOnAddToCartEvent}
          isOpen={isOpen}
        />
      )}
      <Flex
        className="w-full grid grid-cols-2 md:grid-cols-4 gap-5 my-5"
        data-test={selectors.HP.bestsellers}
      >
        {topProducts ? (
          topProducts.map((product) => {
            return (
              <ProductListItem
                key={product.id}
                productType={product.type}
                isCartButtonDisabled={isCartButtonDisabled}
                onAddToCartButtonClick={() => {
                  if (!product.isProductSingleVariant) {
                    setDialogProductData({
                      ...product,
                      productType: product.type,
                      parentSku: product.sku,
                    })
                    onOpen()
                  } else {
                    handleSimpleOnAddToCartEvent({
                      productType: ProductType.Configurable,
                      brand: product.manufacturerInfo.name,
                      category: product.breadcrumbEn,
                      parentSku: product.sku ?? undefined,
                      price: product.finalPrice,
                      productName: product.name,
                      sku: product.singleVariantSku,
                    })
                  }
                }}
                baseUrl={baseUrl ?? ''}
                {...product}
                id={Number(product.id)}
              />
            )
          })
        ) : (
          <>
            {Array.from({ length: 8 }).map((_, index) => (
              <ProductListItemSkeleton key={index} />
            ))}
          </>
        )}
      </Flex>
    </>
  )
}
